<template>
  <div>
    <draggable
      class="dragArea dragAreaParent"
      tag="ul"
      :list="tasks"
      :child="child"
      :group="{ name: 'g1' }"
      :sort="true"
      :indexChild="0"
      @start="drag = true"
      @end="drag = false"
      ghost-class="ghost"
      :move="checkMove"
    >
      <li
        v-for="(item, ind) in tasks"
        :key="item + ind"
        :class="item.page && item.page.length > 1 ? 'itemParent' : 'itemChild'"
      >
        <div v-if="!child && item.page && item.is_page === 0">
          <div
            class="card table-of-contentedit"
            header="目次"
            header-tag="header"
            v-if="item.is_page === 0"
            v-on:mouseenter="x_state = 1"
            v-on:mouseleave="x_state = 0"
          >
            <div
              class="card-header d-flex flex-nowrap table-of-content-chapter"
            >
              <input
                class="form-input w-100 mr-4"
                type="text"
                v-model="item.catalogue_title"
                placeholder=""
              />
              <b-icon
                icon="gear-fill"
                class="my-auto"
                font-scale="2"
                style="border-color: #ced2d8; padding: 4px"
                v-on:click="showSlagConditionDetail(item, ind)"
              />
              <b-icon
                icon="x"
                :class="x_state_fn(x_state) ? x_show : x_hide"
                font-scale="2"
                style="
                  background-color: #ced2d8;
                  border-color: #ced2d8;
                  padding: 4px;
                  border-radius: 50px;
                  right: -1%;
                  top: -10px;
                  cursor: pointer;
                "
                v-on:click="deleteContent(ind, item, item.id)"
              />
            </div>
            <div class="card-body boder-body">
              <nested-draggable
                v-if="item.page"
                :state1="x_state"
                :tasks="item.page"
                :child="true"
                :indexChild="ind"
                :itemParen="item"
                class="dragArea dragAreaChild"
                :move="checkMove"
              >
              </nested-draggable>
              <div>
                <b-button
                  class="custom-button custom-button-plus mx-auto custom-button-add-chapter"
                  href="#"
                  variant="primary"
                >
                  <b-icon
                    icon="plus"
                    aria-hidden="true"
                    style="font-size: 60px"
                    @click="OpenModalPage(item, ind)"
                  ></b-icon>
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!child && item.page && item.is_page === 1">
          <div
            v-for="(ele, index) in item.page"
            v-on:mouseenter="x_state_1 = 1"
            v-on:mouseleave="x_state_1 = 0"
            :key="index"
            class="d-flex flex-direction-column align-items-center position-relative"
            style="
              padding: 0.75rem 1.25rem;
              border: 2px solid #a4a4a4;
              background-color: #fff;
              border-radius: calc(0.25rem);
              margin: 0px 0px 1.5rem;
            "
          >
            <div
              class="border-titlePage mr-4 w-100 text-left"
              style="padding: 0.375rem 0.75rem"
            >
              {{ ele.page_title }}
            </div>

            <b-icon
              v-if="ele.url_redirect"
              icon="pencil-square"
              class="my-auto"
              font-scale="2"
              style="border-color: #ced2d8; padding: 4px; cursor: pointer"
              v-on:click="editPageManual(ind, index)"
            />
            <b-icon
              icon="gear-fill"
              class="my-auto"
              font-scale="2"
              style="border-color: #ced2d8; padding: 4px; cursor: pointer"
              v-on:click="showSlagConditionDetailPage(ind, index)"
            />

            <b-icon
              icon="x"
              :class="x_state_fn(x_state_1) ? x_show : x_hide"
              font-scale="2"
              style="
                border-color: #ced2d8;
                padding: 0px;
                border-radius: 50px;
                right: -10px;
                top: -10px;
                cursor: pointer;
                font-size: 200%;
                background: #ced2d8;
                color: #000;
                width: 20px;
                height: 20px;
              "
              v-on:click="deletePage(index, ele, ind)"
            />
          </div>
        </div>
        <div v-if="child">
          <div
            class="d-flex flex-direction-column align-items-center mb-4 w-100"
            v-on:mouseenter="x_state_1 = 1"
            v-on:mouseleave="x_state_1 = 0"
          >
            <div class="border-titlePage mr-3 w-100 position-relative pl-0">
              <div class="text-left" style="padding: 0.375rem 0.75rem">
                {{ item.page_title }}
              </div>
              <b-icon
                icon="x"
                :class="x_state_fn(state1) ? x_show : x_hide"
                font-scale="2"
                style="
                  border-color: #ced2d8;
                  padding: 0px;
                  border-radius: 50px;
                  right: -10px;
                  top: -10px;
                  cursor: pointer;
                  font-size: 200%;
                  background: #ced2d8;
                  color: #000;
                  width: 20px;
                  height: 20px;
                "
                v-on:click="deletePage(ind, itemParen, indexChild)"
              />
            </div>
            <b-icon
              icon="gear-fill"
              class="my-auto"
              font-scale="2"
              style="border-color: #ced2d8; padding: 4px"
              v-on:click="showSlagConditionDetailPage(indexChild, ind)"
            />
          </div>
        </div>
      </li>
    </draggable>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { eventBus } from "../../main";

export default {
  props: {
    tasks: {
      required: true,
      type: Array,
    },
    state1: {
      required: true,
      type: Number,
    },
    child: {
      type: Boolean,
      default: false,
    },
    indexChild: {
      type: Number,
      default: 1,
    },
    itemParen: {
      type: Object,
    },
  },
  components: {
    draggable,
  },
  name: "nested-draggable",
  data() {
    return {
      x_state: 0,
      x_state_1: 0,
      x_state_2: 0,
      x_show: "my-auto position-absolute",
      x_hide: "my-auto position-absolute d-none",
      itemNoPage: {},
      futureIndex: null,
      objectIndex: null,
    };
  },
  watch: {
    tasks() {
      if (!this.child) {
        let data = this.tasks.filter((item) => item.page === undefined);
        if (data.length) {
          const newData = {
            catalogue_id: null,
            title: null,
            content_id_1: null,
            content_id_2: null,
            content_id_3: null,
            content_id_4: null,
            position: 0,
            page: data,
            is_page: 1,
            slag_id_1: null,
            slag_id_2: null,
            slag_id_3: null,
            slag_id_4: null,
          };
          const indexOb = this.tasks.indexOf(data[0]);
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.tasks[indexOb] = newData;
          this.tasks.filter((item, index) => {
            item.position = index;
            return item;
          });
          console.log(this.tasks);
          return this.tasks;
        } else {
          if (this.objectIndex === null && this.objectIndex === null) return;
          if (
            this.tasks[this.objectIndex] !== undefined &&
            this.tasks[this.objectIndex].page[this.futureIndex] !== undefined &&
            this.tasks[this.objectIndex].page[this.futureIndex].page !==
              undefined
          ) {
            this.tasks[this.objectIndex].page[this.futureIndex] =
              this.tasks[this.objectIndex].page[this.futureIndex].page[0];
          }
        }
      }
    },
  },
  methods: {
    checkMove(evt) {
      if (
        evt.dragged._prevClass === "itemParent" &&
        evt.to.classList.contains("dragAreaChild")
      ) {
        return false;
      } else {
        const topDrag = evt.draggedRect.top;
        const toprelatedRect = evt.relatedRect.top;
        this.objectIndex =
          topDrag > toprelatedRect
            ? evt.draggedContext.index
            : evt.draggedContext.index - 1;
        this.futureIndex = evt.draggedContext.futureIndex;
      }
    },
    x_state_fn(int) {
      return int === 1 ? true : false;
    },
    deletePage(index, ele, ind) {
      eventBus.$emit("deletePageFormComponent", index, ele, ind);
    },
    editPageManual(ind, index) {
      eventBus.$emit("editPageManual", ind, index);
    },
    showSlagConditionDetailPage(ind, index) {
      eventBus.$emit("showSlagConditionDetailPageComponent", ind, index);
    },
    showSlagConditionDetail(ind, index) {
      eventBus.$emit("showSlagConditionDetailComponent", ind, index);
    },
    deleteContent(ind, item, id) {
      eventBus.$emit("deleteContentComponent", ind, item, id);
    },
    OpenModalPage(item, ind) {
      eventBus.$emit("OpenModalPage", item, ind);
    },
  },
};
</script>
<style scoped>
.dragArea {
  min-height: 50px;
  /* outline: 1px dashed; */
  list-style: none;
  padding: 0;
}
.border-titlePage {
  text-align: center;
  padding: 5px 30px 5px 30px;
  border: 2px solid #d9d9d9;
  margin-top: revert;
  margin-bottom: revert;
  background-color: #f2f2f2;
}
pre {
  text-align: start;
}
.custom-button-add-chapter {
  margin: 20px 0px;
  background-color: #00b797;
  border-color: #00b797;
  color: #fff;
}
</style>
